export default {
  async update (Vue, id, formData) {
    await Vue.$api.call(
      'clienteCuenta.update',
      {
        values: {
          idcliente_cuenta: id,
          idbanco: formData.banco.idbanco,
          observacion: formData.observacion,
          principal: formData.principal,
          oficina: formData.oficina,
          nacional: formData.nacional,
          dir_oficina: formData.dir_oficina,
          iban: formData.iban,
          bic: formData.bic,
          ffirmamandato: formData.ffirmamandato,
        },
      },
    )
  },
}
